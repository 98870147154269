import { Button, InteractiveModal, Select } from '@/ui/kit'
import styles from './style.module.scss'
import { t } from '@lingui/macro'
import { useState } from 'react'
import { Control } from '@/ui/atoms/Control'
import { addDaysToDate } from './helpers'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import { useActions } from '@/utils'
import { TVoluntaryBlock } from '@/redux/riskManager/riskManager.types'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSuccessClose: () => void
}

export const PopupBlockTrading = (props: Props) => {
  const { GetVoluntaryBlockTC, GetRiskManagerTradingStatusTC } = useActions(riskManagerActions)
  const [credentials, setCredentials] = useState({
    time: '1',
  })

  const optionsDrawdown = [
    {
      label: t({ id: 'popupBlockTrading.day1', comment: '1 day' }),
      value: '1',
    },
    {
      label: t({ id: 'popupBlockTrading.day3', comment: '3 day' }),
      value: '3',
    },
    {
      label: t({ id: 'popupBlockTrading.day5', comment: '5 day' }),
      value: '5',
    },
  ]

  const handleChangeSelect = (value: string) => {
    setCredentials(prev => ({
      ...prev,
      time: value,
    }))
  }

  const handleSubmit = () => {
    const until = addDaysToDate(parseFloat(credentials.time))

    const payload = {
      disableTrading: true,
      until: until,
    }

    updateVoluntaryBlock(payload)
    props.onSuccessClose()
  }

  async function updateVoluntaryBlock(payload: TVoluntaryBlock) {
    try {
      await GetVoluntaryBlockTC(payload)
      await GetRiskManagerTradingStatusTC()
    } catch (err) {
      console.log('Error', err)
    }
  }

  return (
    <InteractiveModal className={styles.modal} isOpen={props.isOpen}>
      <div className={styles.root}>
        <div className={styles.heading}>
          {t({
            id: 'popupBlockTrading.title',
          })}
          <Control.Close onClick={props.onClose} className={styles.closeControl} />
        </div>
        <div className={styles.content}>
          <Select
            label={t({
              id: 'popupBlockTrading.select',
              comment: 'Drawdown type',
            })}
            options={optionsDrawdown}
            value={credentials.time}
            size={Select.Size.Small}
            onChange={handleChangeSelect}
            toggleClassname={styles.selectToggle}
            labelClassname={styles.selectLabel}
            dropdownClassName={styles.selectDropdown}
          />
        </div>
        <div className={styles.submitLine}>
          <Button.Accent
            label={t({
              id: 'popupBlockTrading.submit',
              comment: 'Submit',
            })}
            className={styles.btnWide}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </InteractiveModal>
  )
}
