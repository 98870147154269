import { TwoColumnPage } from '@/layouts/TwoColumnPage'
import style from './style.module.scss'
import { RiskManagerLeftBar } from '@/ui/organisms/RiskManagerLeftBar'
import { RiskManagerRightBar } from '@/ui/molecules/RiskManagerRightBar'
import { FC } from 'react'

const RiskManager: FC = () => {
  return (
    <TwoColumnPage className={style.root}>
      <RiskManagerLeftBar />
      <RiskManagerRightBar />
    </TwoColumnPage>
  )
}

export { RiskManager }
