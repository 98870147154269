import { FC } from 'react'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { Button, InteractiveModalParts } from '@/ui/kit'
import { TitleH3 } from '@/ui/atoms/TitleH3'

type Props = {
  isSuccess: boolean
  isShowBackButton?: boolean
  title: string
  description: string
  onClose: () => void
  onClickBackButton?: () => void
  titleBackButton?: string
  isShowSupport?: boolean
}

export const ResultForm: FC<Props> = props => {
  function clickBackButton() {
    if (props.onClickBackButton) props.onClickBackButton()
  }

  function closePopup() {
    props.onClose()
  }

  return (
    <div className={style.resultWrap}>
      <InteractiveModalParts.Icon icon={props.isSuccess ? 'success' : 'failed'} />
      <span className={style.resultTitle}>
        <TitleH3 label={props.title} />
      </span>
      <span className={style.resultDescription}>{props.description}</span>
      {props.isShowBackButton && (
        <InteractiveModalParts.Button
          text={props.titleBackButton || 'Back'}
          variant={Button.Variant.White}
          onClick={clickBackButton}
        />
      )}
      <InteractiveModalParts.Button
        text={t({ id: 'okxAffiliate.resultForm.close', comment: 'Close' })}
        variant={Button.Variant.Primary}
        onClick={closePopup}
      />
      {props.isShowSupport && (
        <div className={style.support}>
          {t({
            id: 'okxAffiliate.resultForm.support',
            comment: 'or you can contact support: broker.support@tiger.сom',
          })}
        </div>
      )}
    </div>
  )
}

ResultForm.defaultProps = {
  isShowBackButton: false,
}
